<template>
  <div class="container">
    <div class="row padding-xl">
      <div class="col-6">
        <img :src="info.avatar" class="avatar" />
      </div>
      <div class="col-18 col-align-center">
        <div class="row row-align-start">
          <span class="font-xl font-bold margin-right">{{ info.nick_name }}</span>
          <md-tag shape="fillet" type="fill" fill-color="#262626" font-weight="normal" font-color="#ffc53d" v-if="isPro && !isSuperPro">
            企业版
          </md-tag>
          <md-tag shape="fillet" type="fill" fill-color="#262626" font-weight="normal" font-color="#ffc53d" v-else-if="isSuperPro">
            专业版
          </md-tag>
          <md-tag shape="fillet" type="fill" fill-color="#262626" font-weight="normal" font-color="#ffc53d" v-else-if="isTest">
            试用版
          </md-tag>
          <md-tag shape="fillet" type="fill" fill-color="#262626" font-weight="normal" font-color="#ffc53d" v-else>
            个人版
          </md-tag>
        </div>
        <p class="padding-top-xs font-sm" v-if="isPro || isSuperPro">{{ info.invite_code.client.name }}</p>
        <p class="padding-top-xs font-sm" v-if="isPro || isSuperPro">有效期至：{{ info.invite_code.client.end_time }}</p>
        <p class="padding-top-xs font-sm" v-if="isTest">试用期至：{{ info.test_time }}</p>
        <p class="padding-top-xs font-sm" v-if="!isTest && !isSuperPro && !isPro">试用期已过，请联系客服</p>
      </div>
    </div>
    <md-field class="margin-lr shadow-lg top-card">
      <md-cell-item title="升级企业版" arrow @click="$router.push({ name: 'upgrade' })" v-if="!info.invite_code_id">
        <md-icon class="icon" name="star" size="lg" slot="left" svg></md-icon>
      </md-cell-item>
      <md-cell-item title="我的检查要点报告" arrow @click="$router.push({ name: 'my-answer' })">
        <md-icon class="icon" name="document" size="lg" slot="left" svg></md-icon>
      </md-cell-item>
      <md-cell-item title="我的建筑评判结果" arrow @click="$router.push({ name: 'my-result' })">
        <md-icon class="icon" name="document" size="lg" slot="left" svg></md-icon>
      </md-cell-item>
    </md-field>
    <div class="center ver color-gray7">
      <p>当前版本:2.0.4</p>
      <p>更新日期:2021-02-09</p>
    </div>
  </div>
</template>

<script>
import '@/assets/svg/document.svg'
import '@/assets/svg/star.svg'

export default {
  data() {
    return {
      info: this.$store.getters['user/info'],
      isPro: this.$store.getters['user/isPro'],
      isSuperPro: this.$store.getters['user/isSuperPro'],
      isTest: this.$store.getters['user/isTest'],
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (to.params.from === 'upgrade') {
        vm.$store.dispatch('user/GetUserInfo')
      }
    })
  },
}
</script>

<style lang="stylus" scoped>
@import '../styles/variable'
.container
  padding-bottom 100px
  .avatar
    width 120px
    height 120px
    border-radius 10px
  .md-field
    padding-top 0
    border-radius 10px
  >>>.md-tag .shape-fillet
    border-radius 4px
    padding 6px 8px
  >>>.md-tag .size-large
    font-size 22px
  .ver
    position fixed
    width 100%
    bottom 140px
</style>
